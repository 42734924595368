import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import UniversalCard from '../components/molecules/UniversalCard';
import Newsletter from '../components/molecules/Newsletter';

import usePagination from '../utils/usePagination';

import imgTemplate from '../assets/images/pages/blog/template.png';
import ImgLeft from '../assets/images/pages/blog/left.inline.svg';
import ImgRight from '../assets/images/pages/blog/right.inline.svg';

import '../assets/styles/pages/blog.scss';
import CFS from '../components/molecules/CFS';

const POSTS_PER_PAGE = 9;
const BlogPage = ({ data }) => {
  const featuredPost = data.featuredPost.edges;
  const allPosts = data.allPosts.edges;
  const popularPosts = data.popularPosts.edges;

  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    allPosts,
    POSTS_PER_PAGE
  );

  const renderPageButtons = () => {
    const btns = [];
    for (let page = 1; page <= maxPage; page++) {
      btns.push(page);
    }
    return btns.map((page, index) => (
      <button
        key={index}
        onClick={() => jump(page)}
        className={`pagination__btn ${currentPage === page ? 'pagination__btn--active' : ''}`}
      >
        {page}
      </button>
    ));
  };

  return (
    <Layout
      title="Blog | ELE Barcelona"
      description="Aprende sobre un gestor cultural en clase de ELE, resuelve dudas existenciales sobre la gramática o infórmate de las salidas laborales como profesor ELE."
    >
      <div className="blog">
        <CFS title="Explora Nuestro Blog" />
        <section className="second-section">
          <div className="container">
            {/* <div className="row">
              <div className="col">
                <h1 className="c-title-34 first-section__title">Blog</h1>
              </div>
            </div> */}
            <div className="row top">
              <div className="col-lg-8">
                <div className="row">
                  {featuredPost.map(({ node }, index) => (
                    <div className="col-lg-6" key={index}>
                      <UniversalCard
                        title={node?.data?.title?.text}
                        description={`${node?.data?.content?.text.slice(0, 250)}...`}
                        btn={{ to: `/blog/${node.uid}`, text: 'Leer más' }}
                        img={node?.data?.image?.url}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-lg-4 order-lg-3 order-1">
                {popularPosts.map(({ node }, index) => (
                  <Link to={`/blog/${node.uid}`} className="blog-aside" key={index}>
                    <h3 className="blog-aside__title">{node?.data?.title?.text}</h3>
                    <p className="blog-aside__description">
                      {`${node?.data?.content?.text.slice(0, 150)}...`}
                    </p>
                  </Link>
                ))}
              </div>
            </div>
            <div className="row middle">
              {currentData().map(({ node }, index) => (
                <div className="col-lg-4" key={index}>
                  <UniversalCard
                    title={node?.data?.title?.text}
                    description={`${node?.data?.content?.text.slice(0, 250)}...`}
                    btn={{ to: `/blog/${node.uid}`, text: 'Leer más' }}
                    img={node?.data?.image?.url}
                  />
                </div>
              ))}
            </div>

            <div className="row">
              <div className="col">
                <div className="pagination">
                  <button onClick={() => prev()} className="pagination__btn pagination__btn--prev">
                    <ImgLeft />
                  </button>
                  {renderPageButtons()}
                  <button onClick={() => next()} className="pagination__btn pagination__btn--next">
                    <ImgRight />
                  </button>
                </div>
              </div>
            </div>

            <div className="row cta-block">
              <div className="col">
                <Newsletter />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPosts: allPrismicArticle(
      filter: { data: { featured_post: { eq: false } } }
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
            image {
              url
            }
          }
        }
      }
    }
    featuredPost: allPrismicArticle(
      filter: { data: { featured_post: { eq: true } } }
      limit: 2
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
            image {
              url
            }
          }
        }
      }
    }
    popularPosts: allPrismicArticle(
      filter: { data: { popular_post: { eq: true } } }
      limit: 3
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
          }
        }
      }
    }
  }
`;

export default BlogPage;
